import { Content } from "@prismicio/client";
import cn from "clsx";
import React, { useState } from "react";
import { TitlePage } from "@components/commun";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { components } from "slices";
import style from "./HomeProduct.module.scss";
import Card from "./Card/Card";

type PageProps = {
  page: Content.BlockSliderDocument;
  bar?: Content.BarreProgrammeSlice;
};

export default function HomeProduct({ page, bar }: PageProps) {
  const [category, setCategory] = useState(
    page.data.slices[0]?.primary.category,
  );

  return (
    <div className={style.root}>
      <div className={style.head}>
        <div className="lk-wrap">
          <div className="text-center">
            <div>
              <TitlePage styleClass="caveat" className={style.title}>
                <PrismicRichText field={page.data.title} />
              </TitlePage>
            </div>
            <div className={style.subtitle}>
              <PrismicRichText field={page.data.subtitle} />
            </div>
          </div>
        </div>
      </div>
      <div className={style.tabs}>
        {page.data.slices.map((slice, index) => {
          const numberOfItems =
            slice.primary.category === category && slice.primary.count;

          return (
            <div
              className={cn(style.tab, {
                [style.active]: slice.primary.category === category,
              })}
              key={index}
            >
              <span
                role="presentation"
                onClick={() => setCategory(slice.primary.category)}
              >
                {numberOfItems} {slice.primary.category}
              </span>
            </div>
          );
        })}
      </div>
      <div className={style.content}>
        <div className={style.row}>
          <div className={style.col}>
            <Card slice={page} />
          </div>
          <div className={style.col}>
            <div>
              {page.data.slices.map((slice, index) =>
                slice.primary.category === category ? (
                  <SliceZone
                    key={index}
                    slices={[slice]}
                    components={components}
                  />
                ) : null,
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
